<template>
  <div class="flex flex-col w-full max-w-8xl m-auto">
    <div class="flex flex-col-reverse items-center md:flex-row justify-between">
      <div class="px-4 py-6 md:px-12 md:py-8 lg:px-20 lg:py-12 flex flex-col space-y-4 lg:space-y-8 w-full lg:w-1/2 my-auto">
        <h3 class="text-orange-400 text-xl md:text-2xl lg:text-3xl font-bold flex space-x-2 md:space-x-4 items-center">
          <i class="rounded-full border border-solid border-orange-400 shrink-0 w-10 h-10 md:w-12 md:h-12 flex justify-center items-center">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6667 17.5555V18.1111C18.6667 19.0315 17.9205 19.7777 17 19.7777H3.66667C2.74618 19.7777 2 19.0315 2 18.1111V9.22217C2 8.30168 2.74618 7.5555 3.66667 7.5555H4.22222V14.7777C4.22222 16.3094 5.46833 17.5555 7 17.5555H18.6667ZM22 14.7777V5.88883C22 4.96835 21.2538 4.22217 20.3333 4.22217H7C6.07951 4.22217 5.33333 4.96835 5.33333 5.88883V14.7777C5.33333 15.6982 6.07951 16.4444 7 16.4444H20.3333C21.2538 16.4444 22 15.6982 22 14.7777ZM10.8889 7.5555C10.8889 8.47599 10.1427 9.22217 9.22222 9.22217C8.30174 9.22217 7.55556 8.47599 7.55556 7.5555C7.55556 6.63502 8.30174 5.88883 9.22222 5.88883C10.1427 5.88883 10.8889 6.63502 10.8889 7.5555ZM7.55556 12.5555L9.48316 10.6279C9.64587 10.4652 9.90969 10.4652 10.0724 10.6279L11.4444 11.9999L16.1498 7.29456C16.3125 7.13186 16.5764 7.13186 16.7391 7.29456L19.7778 10.3333V14.2222H7.55556V12.5555Z" fill="#E9EAEC" />
            </svg>
          </i>
          <span>
            100,000+ Assets
          </span>
        </h3>
        <h4 class="text-3xl lg:text-4xl font-bold text-lightgray-100 leading-10">
          Find the right assets faster on MattePaint<span class="text-orange-500">.</span>
        </h4>
        <p class="lg:text-lg font-medium">
          Stop wasting hours searching for the perfect image. Unlock access to thousands of high-quality photographs of Mountains, Valleys, Skies, Cities, Deserts, and more—captured at different times of the day for every project need..
        </p>
        <p class="lg:text-lg font-medium">
          The MattePaint team are VFX veterans, every photo is shot with purpose and understanding of the VFX pipeline.
        </p>
      </div>
      <div class="relative w-full lg:w-1/2 flex items-center">
        <img
          :src="bg1"
          :srcset="srcset1"
          loading="lazy"
          fetchpriority="high"
          noPrefetch
          class="w-full object-cover m-0"
          alt="Find the right assets faster on MattePaint"
        />
      </div>
    </div>

    <div class="flex flex-col md:flex-row justify-between">
      <div class="relative w-full lg:w-1/2 flex items-center">
        <img
          :src="bg2"
          :srcset="srcset2"
          loading="lazy"
          fetchpriority="low"
          noPrefetch
          class="w-full object-cover m-0"
          alt="Stop using Low-quality 8bit JPG’s in your productions."
        />
      </div>
      <div class="px-4 py-6 md:px-12 md:py-8 lg:px-20 lg:py-12 flex flex-col space-y-4 lg:space-y-8 w-full lg:w-1/2 my-auto">
        <h3 class="text-orange-400 text-xl md:text-2xl lg:text-3xl font-bold flex space-x-2 md:space-x-4 items-center">
          <i class="rounded-full border border-solid border-orange-400 shrink-0 w-10 h-10 md:w-12 md:h-12 flex justify-center items-center">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C6.47702 2 2 6.47702 2 12C2 17.523 6.47702 22 12 22C17.523 22 22 17.523 22 12C22 6.47702 17.523 2 12 2ZM12 17.1613C11.2875 17.1613 10.7097 16.5835 10.7097 15.871C10.7097 15.1585 11.2875 14.5806 12 14.5806C12.7125 14.5806 13.2903 15.1585 13.2903 15.871C13.2903 16.5835 12.7125 17.1613 12 17.1613ZM12 12C9.8621 12 8.12903 13.7331 8.12903 15.871C8.12903 18.0089 9.8621 19.7419 12 19.7419C7.72419 19.7419 4.25806 16.2758 4.25806 12C4.25806 7.72419 7.72419 4.25806 12 4.25806C14.1379 4.25806 15.871 5.99113 15.871 8.12903C15.871 10.2669 14.1379 12 12 12ZM12 6.83871C11.2875 6.83871 10.7097 7.41653 10.7097 8.12903C10.7097 8.84153 11.2875 9.41935 12 9.41935C12.7125 9.41935 13.2903 8.84153 13.2903 8.12903C13.2903 7.41653 12.7125 6.83871 12 6.83871Z" fill="#E9EAEC" />
            </svg>

          </i>
          <span>
            32bit EXR | 16bit RAW
          </span>
        </h3>
        <h4 class="text-3xl lg:text-4xl font-bold text-lightgray-100 leading-10">
          Stop using Low-quality 8bit JPG’s in your productions<span class="text-orange-500">.</span>
        </h4>
        <p class="lg:text-lg font-medium">
          Unlike other platforms, all our assets are calibrated to a neutral grade with no clipping and are available as 32bit EXR or 16bit RAW files you won’t find anywhere else.
        </p>
        <p class="lg:text-lg font-medium">
          Get access to world-class images, save time, focus on creativity and increase quality.
        </p>
      </div>
    </div>

    <div class="flex flex-col-reverse md:flex-row justify-between">
      <div class="px-4 py-6 md:px-12 md:py-8 lg:px-20 lg:py-12 flex flex-col space-y-4 lg:space-y-8 w-full lg:w-1/2 my-auto">
        <h3 class="text-orange-400 text-xl md:text-2xl lg:text-3xl font-bold flex space-x-2 md:space-x-4 items-center">
          <i class="rounded-full border border-solid border-orange-400 shrink-0 w-10 h-10 md:w-12 md:h-12 flex justify-center items-center">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C6.47714 2 2 6.47714 2 12C2 17.5229 6.47714 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47714 17.5229 2 12 2ZM12 20.0645C7.54306 20.0645 3.93548 16.4576 3.93548 12C3.93548 7.5431 7.54238 3.93548 12 3.93548C16.4569 3.93548 20.0645 7.54238 20.0645 12C20.0645 16.4569 16.4576 20.0645 12 20.0645ZM16.3287 15.9894C15.941 16.381 14.4928 17.6585 12.1325 17.6585C8.80871 17.6585 6.46782 15.1817 6.46782 11.9502C6.46782 8.75859 8.89827 6.32919 12.1034 6.32919C14.3425 6.32919 15.6815 7.40258 16.0386 7.73157C16.1225 7.80874 16.1764 7.91311 16.1906 8.0262C16.2049 8.13929 16.1787 8.25379 16.1167 8.34939L15.3846 9.48298C15.2297 9.7229 14.9021 9.77661 14.679 9.60077C14.3324 9.32754 13.3962 8.69198 12.1908 8.69198C10.2431 8.69198 9.04899 10.1166 9.04899 11.9211C9.04899 13.5981 10.1332 15.2958 12.2053 15.2958C13.5221 15.2958 14.4974 14.5281 14.8556 14.198C15.0681 14.0021 15.4038 14.0351 15.5741 14.2681L16.3751 15.3636C16.4434 15.4563 16.4763 15.5703 16.4677 15.6851C16.4592 15.7999 16.4099 15.9078 16.3287 15.9894Z" fill="#E9EAEC" />
            </svg>

          </i>
          <span>
            Risk-Free Copyright
          </span>
        </h3>
        <h4 class="text-3xl lg:text-4xl font-bold text-lightgray-100 leading-10">
          MattePaint offers a copyright-free asset delivery contracts<span class="text-orange-500">.</span>
        </h4>
        <p class="lg:text-lg font-medium">
          We've got your back when it comes to delivering MattePaint assets to your clients. We take the hassle and time out of worrying what assets you can and cannot deliver to your clients in the final production delivery.
        </p>
        <p class="lg:text-lg font-medium">
          Your artists can relax and production can rest at ease knowing that MattePaint has a risk-free copyright solution now and for future works.
        </p>
      </div>
      <div class="relative w-full lg:w-1/2 flex items-center">
        <img
          :src="bg3"
          :srcset="srcset3"
          loading="lazy"
          fetchpriority="low"
          noPrefetch
          class="w-full object-cover m-0"
          alt="MattePaint offers a copyright-free asset delivery contracts."
        />
      </div>
    </div>
  </div>
</template>

<script>
import xlbg1 from '~/assets/img/home/features/1/2x.jpg';
import lbg1 from '~/assets/img/home/features/1/1x.jpg';
import mbg1 from '~/assets/img/home/features/1/mobile.jpg';

import xlbg2 from '~/assets/img/home/features/2/2x.jpg';
import lbg2 from '~/assets/img/home/features/2/1x.jpg';
import mbg2 from '~/assets/img/home/features/2/mobile.jpg';

import xlbg3 from '~/assets/img/home/features/3/2x.jpg';
import lbg3 from '~/assets/img/home/features/3/1x.jpg';
import mbg3 from '~/assets/img/home/features/3/mobile.jpg';

export default {
  data() {
    return {
      bg1: mbg1, // default image
      srcset1: `${mbg1} 500w, ${lbg1} 1024w, ${xlbg1} 1920w`,
      bg2: mbg2, // default image
      srcset2: `${mbg2} 500w, ${lbg2} 1024w, ${xlbg2} 1920w`,
      bg3: mbg3, // default image
      srcset3: `${mbg3} 500w, ${lbg3} 1024w, ${xlbg3} 1920w`,
    };
  },
}
</script>
